.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  vertical-align: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  font-size: calc(0px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.parent-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-element {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
}

.bg {
  background-color: #282c34 !important;
}

div{
  color:#fff;
}

footer {
  position: absolute;
  width:100vw;
}

label {
  color:#111;
  margin:0px !important;
  padding:0px !important;
}

.btn-main {
  background-color: #282c34 !important;
  color:#fff !important;
}

.error-message{
  color: red;
}

.hiddenDiv{
  display:none !important;
}

.blueInput {
  border: solid 2px #a8dadc;
}

.redInput {
  border: solid 2px #e63946;
}

.blueText {
  color: #a8dadc;
}

.redText {
  color: #e63946;
}

.blueBG {
  background-color: #a8dadc !important;
}

.redBG {
  background-color: #e63946 !important;
}

.errorMessage {
  color:red;
}

.alertSuccess {
  color: red;
}

.noPadMarElement {
  padding:0px;
  margin:0px;
}

.clickable {
  cursor:pointer;
}

.underline {
  text-decoration:underline;
}

.alignRight {
  text-align: right;
}

.roundedCornersWithBorder {
  border-radius: 25px;
  border: 2px solid #000;
  padding: 20px;
  width: 200px;
  height: 150px;
}

/* DataGrid */
.DGdflex {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.DGrow {
/*  display: flex;*/
  width: 100%;
}

.DGcolumn {
  width: 100%;
}

.hideRow {
  transform: translateY(-100%);
  transition: transform 0.5s ease-in-out;
}

.showRow {
  transform: translateY(0);
  transition: transform 0.5s ease-in-out;
}

.side-drawer {
  /* In commentaries are the way to make it slide from left to right */
  position: fixed;
  background: black;
  top: 0;
  left: 0;
  /* right: 100%; */
  width: 20%;
  height:100%;
  z-index: 200;
  border: 1px solid #fff;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(-100%);
  /* transform: translateX(0%); */
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
  /* transform: translateX(100%); */
}