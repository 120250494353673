.month_default_cell_header {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 12px;
  color: #333;
  border-bottom: 1px solid #eee;
  width:100%;
}

.addedDiv {
  font-size: 10px;
  margin-top: 30px;
}

.addedDivDiv {
  font-size: 10px;
  float: left;
  text-align: center;
  width: 49%;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.hover-text {
  cursor: pointer;
  position: relative;
}

.zoom {
  /*transition: transform .2s; /* Animation */
}

.zoom:hover {
  /*transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  color:#111;
}